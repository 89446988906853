import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fff;
    --black: #0a0023;
    --intro_background: #221d42;
    --black_07: rgba(10, 0, 35, 0.7);
    --grey_aa: #aaa;
    --grey_88: #888;
    --turquoise: #40ffdf;
    --turquoise_02: rgba(64, 255, 223, 0.2);
    --turquoise_05: rgba(64, 255, 223, 0.5);
    --turquoise_09: rgba(64, 255, 223, 0.9);
    --pink: #fc58ff;
    --pink_05: rgba(252, 88, 255, 0.5);
    --pink_09: rgba(252, 88, 255, 0.9);
        
    --font-family: Inter, sans-serif;
    
    --400-16: 400 16px/25px var(--font-family);
    --400-21: 400 21px/35px var(--font-family);

    --500-21: 500 21px/35px var(--font-family);
    --500-34: 500 34px/41px var(--font-family);
    --500-55: 500 55px/64px var(--font-family);

    --900-16: 900 16px/20px var(--font-family);
    --900-21: 900 21px/25px var(--font-family);
    --900-34: 900 34px/41px var(--font-family);
    --900-55: 900 55px/66px var(--font-family);
    --900-89: 900 89px/107px var(--font-family);

    --max-outer-width: 1540px;
    --max-width: 1240px;
    --min-padding: 16px;
    --max-content: max(var(--min-padding), calc((100% - var(--max-width)) / 2));

    --8: 8px;
    --16: 16px;
    --24: 24px;
    --32: 32px;
    --40: 40px;
    --64: 64px;
  }

  @media (max-width: 768px) {
    .desktop-only.desktop-only {
      display: none;
    }
  }

  @media (min-width: 769px) {
    :root {
      --8: 16px;
      --16: 32px;
      --24: 48px;
      --32: 64px;
      --40: 80px;
      --64: 128px;
    }

    .mobile-only.mobile-only {
      display: none;
    }
  }

  html {
    scroll-behavior: smooth;

    @media (min-width: 769px) {
      height: 100vh;
      margin: 0;
      overflow: hidden;
    }
  }

  body {
    margin: 0;
    color: var(--black);
    font: var(--400-16);
    box-sizing: border-box;
    height: 100vh;
    overflow: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scroll-padding: 0;

    @media (min-width: 769px) {
      font: var(--400-21);
      height: 100vh;
      overflow: auto;
      scroll-snap-type: y mandatory;
      scroll-padding: 0;
    }
  }

  *, *::before, *::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  strong {
    font-weight: 900;
  }

  h1, h2, p {
    margin: 0;
    
    @media (max-width: 768px) {
      word-break: break-word;
    }
  }

  h1 {
    margin-bottom: 16px;
    font: var(--500-34);

    @media (min-width: 769px) {
      margin-bottom: 32px;
      font: var(--500-55);
    }

    em {
      font-style: normal;
      
      .dark & {
        color: var(--turquoise);
      }
  
      .light & {
        color: var(--black);
      }
    }
  }

  h2 {
    font: var(--500-21);

    @media (min-width: 769px) {
      font: var(--500-34);
    }

    em {
      font-style: normal;
      
      .dark & {
        color: var(--turquoise);
      }
  
      .light & {
        color: var(--black);
      }
    }
  }

  .underlined {
    width: min-content;
    white-space: nowrap;
    position: relative;
    font: var(--900-16);
    text-transform: uppercase;
    z-index: 0;

    @media (min-width: 769px) {
      font: var(--900-21);
    }

    &::after {
      content: '';
      width: calc(100% / 1.5);
      height: 8px;
      
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      
      background: var(--turquoise);
      
      .dark & {
        background: var(--pink);
      }

      .light & {
        background: var(--turquoise);
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button,
  svg {
    flex: none;
  }

  .dark {
    color: var(--white);
  }

  .light {
    color: var(--black);
  }

  .glowing-border {
    border: 1px solid var(--grey_aa);
    border-radius: 8px;
    box-shadow: 0 5px 34px rgba(64, 255, 223, 0.2);
  }

  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100svh;
  }

  .hidden,
  .hidden-container > * {
    opacity: 0;
    transform: translateY(5%);
    transition-property: opacity, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

  .static-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .visible {
    opacity: 1;
    transform: translateY(0);
  }
`

export default GlobalStyles
