import React from 'react'
import styled from 'styled-components'
import { useContent } from '../content'
import Section from '../components/Section'
import { MonochromaticLogo } from './Icons'

export default function Footer() {
  const { navigation, contact, footer: texts } = useContent().content

  return (
    <StyledFooter as="footer" className="hidden-container">
      <Lists>
        <List>
          <strong>{texts.quickLinks}</strong>

          <a href={navigation.offer.url} target="_blank">
            {navigation.offer.label}
          </a>

          <a href={navigation.caseStudies.url} target="_blank">
            {navigation.caseStudies.label}
          </a>

          <a href={navigation.people.url} target="_blank">
            {navigation.people.label}
          </a>
        </List>

        <List>
          <strong>{texts.contactUs}</strong>
          <a href={`tel:${contact.phone.value}`}>{contact.phone.value}</a>
          <a href={`mailto:${contact.email.value}`}>{contact.email.value}</a>
        </List>
      </Lists>

      <Logo>
        <MonochromaticLogo />
        <span>{texts.slogan}</span>
      </Logo>

      <Copyright>{texts.copyright}</Copyright>
    </StyledFooter>
  )
}

const StyledFooter = styled(Section)`
  gap: 32px;
  margin-top: auto;
  border-top: 1px solid var(--grey_aa);

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: 769px) {
    display: grid;
    grid-template:
      'logo lists'
      'logo copyright';
    justify-content: space-between;
    scroll-snap-align: center;
  }
`

const Lists = styled.div`
  grid-area: lists;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 769px) {
    flex-direction: row;
    gap: 64px;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font: var(--500-21);
  line-height: normal;
`

const Logo = styled.div`
  grid-area: logo;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 32px 0;
  font: var(--500-21);

  @media (min-width: 769px) {
    margin: unset;
    font: var(--500-34);
  }

  svg {
    width: 60px;

    @media (min-width: 769px) {
      width: 85px;
    }
  }
`

const Copyright = styled.span`
  grid-area: copyright;
  font: var(--400-16);

  @media (min-width: 769px) {
    justify-self: flex-end;
  }
`
