import type { SVGAttributes } from 'react'
import React from 'react'

export const Phone = (props: SVGAttributes<SVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M7 23a2 2 0 0 1-1.4-.6A2 2 0 0 1 5 21V3c0-.5.2-1 .6-1.4A2 2 0 0 1 7 1h10c.6 0 1 .2 1.4.6.4.4.6.9.6 1.4v18c0 .6-.2 1-.6 1.4a2 2 0 0 1-1.4.6H7Zm0-5v3h10v-3H7Zm5 2.5c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7a1 1 0 0 0-.3-.7 1 1 0 0 0-.7-.3 1 1 0 0 0-.7.3 1 1 0 0 0-.3.7c0 .3.1.5.3.7.2.2.4.3.7.3ZM7 16h10V6H7v10ZM7 4h10V3H7v1Z" />
  </svg>
)

export const Send = (props: SVGAttributes<SVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M3 20V4l19 8-19 8Zm1.5-2.3L18.1 12 4.5 6.2v4.2l6 1.6-6 1.5v4.2Zm0 0V6.3v11.4Z" />
  </svg>
)

export const BusinessCenter = (props: SVGAttributes<SVGElement>) => (
  <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24" {...props}>
    <path d="M3.5 21c-.4 0-.8-.1-1-.4-.4-.4-.5-.7-.5-1.1V7.3c0-.4.1-.8.5-1 .3-.4.6-.5 1-.5h4.8V3.5c0-.4.1-.8.4-1 .3-.4.7-.5 1-.5h4.6c.3 0 .7.1 1 .5.3.3.4.6.4 1v2.3h4.8c.4 0 .8.1 1 .4.4.3.5.7.5 1v12.3c0 .4-.1.8-.4 1-.4.4-.7.5-1.1.5h-17ZM9.8 5.8h4.4V3.5H9.8v2.3Zm10.7 9h-6.2v1.4H9.8v-1.5H3.5v4.8h17v-4.8Zm-9.2 0h1.5v-1.6h-1.5v1.5Zm-7.8-1.6h6.3v-1.5h4.5v1.5h6.2v-6h-17v6Z" />
  </svg>
)

export const MonochromaticLogo = (props: SVGAttributes<SVGElement>) => (
  <svg width="85" height="129" fill="currentColor" viewBox="0 0 85 129" {...props}>
    <path d="M21.3 21.4V0H0v21.4h21.3ZM0 128.6c0-11.8 9.5-21.4 21.3-21.4h21.2A42.7 42.7 0 0 0 85 64.3c0-23.7-19-42.9-42.5-42.9H21.2V43h21.3a21.4 21.4 0 0 1 0 42.9H21.2A21.3 21.3 0 0 0 0 107.2v21.4Z" />
  </svg>
)
