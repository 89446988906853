import React from 'react'
import ContentProvider from './src/content'
import Footer from './src/components/Footer'
import GlobalStyles from './src/styles'

function App({ children }) {
  return (
    <ContentProvider>
      <GlobalStyles />
      {children}
      <Footer />
    </ContentProvider>
  )
}

export const wrapPageElement = ({ element }) => <App>{element}</App>

function showWhenInViewport(elements: NodeListOf<Element>, rootMargin = '-100px 0px -100px 0px') {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) entry.target.classList.add('visible')
      })
    },
    { rootMargin }
  )

  elements.forEach(element => {
    observer.observe(element)
  })
}

function showImmediately(elements: NodeListOf<Element>) {
  elements.forEach(element => {
    element.classList.add('visible')
  })
}

setTimeout(() => {
  if (typeof document === 'undefined') return
  showWhenInViewport(document.querySelectorAll('.hidden-container > *'))
  showWhenInViewport(document.querySelectorAll('.hidden'))
  showImmediately(document.querySelectorAll('.static-hidden'))
}, 200)
