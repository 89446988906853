import type { ReactNode } from 'react'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as Icons from './components/Icons'
import expertiseImage from './assets/logo-dark.svg'

import chattingImage from './assets/efficiency/chatting.svg'
import cloudHostingImage from './assets/efficiency/cloud-hosting.svg'
import coinsImage from './assets/efficiency/coins.svg'
import dashboardImage from './assets/efficiency/dashboard.svg'
import fastLoadingImage from './assets/efficiency/fast-loading.svg'
import robotArmImage from './assets/efficiency/robot-arm.svg'
import userFlowImage from './assets/efficiency/user-flow.svg'

export type EfficiencyItem = {
  title: ReactNode
  text: ReactNode
  image: ReactNode
}

const efficiencyItems: EfficiencyItem[] = [
  {
    title: (
      <strong>
        Build workflows fast
        <br />
        no IT Required
      </strong>
    ),
    text: (
      <>
        Take control of your processes. Set up or modify loan approvals and customer onboarding
        instantly. Adjust approval rules or scoring on the fly with our intuitive no-code decision
        engine.
      </>
    ),
    image: <img src={userFlowImage} alt="" />
  },
  {
    title: (
      <strong>
        Instant connections
        <br />
        to your data providers
      </strong>
    ),
    text: (
      <>
        Connect directly to your KYC, AML, and other essential providers without delay. Accelerate
        onboarding and reduce costs—all without external slowdowns.
      </>
    ),
    image: <img src={cloudHostingImage} alt="" />
  },
  {
    title: (
      <strong>
        Automate decisions
        <br />
        and customer interactions
      </strong>
    ),
    text: (
      <>
        Let JeeFlo handle loan approvals and customer communications automatically. Use data from
        applications and third-party sources to approve loans. Send timely emails or texts based on
        each customer's loan stage, using our ready-made templates.
      </>
    ),
    image: <img src={chattingImage} alt="" />
  },
  {
    title: (
      <strong>
        Make decisions
        <br />
        automatically
      </strong>
    ),
    text: (
      <>
        JeeFlo’s decision engine lets you automate how you approve or reject loans, using data from
        both applications and third-party sources. Skip the manual work and let the system handle it
        for you.
      </>
    ),
    image: <img src={robotArmImage} alt="" />
  },
  {
    title: <strong>Test and adapt quickly</strong>,
    text: (
      <>
        Experiment with new loan products or strategies using JeeFlo’s A/B testing. Find out what
        works before full rollout—no guesswork involved.
      </>
    ),
    image: <img src={fastLoadingImage} alt="" />
  },
  {
    title: <strong>Cut down your costs</strong>,
    text: (
      <>
        You don’t need an in-house IT team or expensive integrations. JeeFlo lets you scale while
        saving both time and money.
      </>
    ),
    image: <img src={coinsImage} alt="" />
  },
  {
    title: (
      <strong>
        Stay on top of everything,
        <br />
        in real time
      </strong>
    ),
    text: (
      <>
        Track every loan, application, and transaction in one place. JeeFlo keeps an audit trail of
        everything, so you’re always in control—without the mess.
      </>
    ),
    image: <img src={dashboardImage} alt="" />
  }
]

export type UseCase = {
  title: ReactNode
  image: ReactNode
  introText: ReactNode
  fullText: ReactNode
}

const currentYear = new Date().getFullYear()

const en = {
  navigation: {
    offer: { label: <>What we offer</>, url: 'https://impactdev.io/#offer' },
    caseStudies: { label: <>Case studies</>, url: 'https://impactdev.io/#case-studies' },
    people: { label: <>People</>, url: 'https://impactdev.io/#people' },
    contact: { label: <>Contact us</>, url: 'https://impactdev.io/#contact' }
  },
  contact: {
    underlined: <>Contact us</>,
    h1: <>Let's talk</>,
    p: <>Let's discuss your idea and make impact on the market.</>,
    phone: {
      icon: <Icons.Phone />,
      title: <>Phone</>,
      value: '+48 794 488 927'
    },
    email: {
      icon: <Icons.Send />,
      title: <>Email</>,
      value: 'contact@impactdev.io'
    },
    linkedIn: {
      icon: <Icons.BusinessCenter />,
      title: <>LinkedIn</>,
      label: <>Click here</>,
      value: 'https://www.linkedin.com/showcase/jeeflo'
    },
    bookADemo: 'https://calendly.com/piotr-kamoda'
  },
  intro: {
    title: (
      <>
        <strong>Scale your lending</strong> operations fast.
        <br />
        <strong>
          <em>No code needed.</em>
        </strong>
      </>
    ),
    subtitle: (
      <>
        JeeFlo is a platform designed for lending companies that need to move quickly. We put you in
        control of your loan processes, so you can set up and adjust workflows in real time — all
        without waiting on IT teams.
        <br />
        Whether it's loan approvals or customer onboarding,
        <br />
        do it all without writing a single line of code.
      </>
    ),
    bookADemo: <>Book a demo</>,
    scrollDown: <>Scroll down</>
  },
  efficiency: {
    underlined: <>Efficiency</>,
    h1: (
      <>
        How <strong>JeeFlo helps you</strong>
        <br /> work faster and save more
      </>
    ),
    efficiencyItems
  },
  expertise: {
    underlined: <>Expertise</>,
    h1: (
      <>
        <strong>Why</strong> JeeFlo?
      </>
    ),
    description: (
      <>
        We’re fintech experts who understand the challenges you face—long development cycles, manual
        processes, and complex integrations. With over 10 years of experience, we've built JeeFlo
        to&nbsp;solve these problems. This isn't just software; it's a solution crafted by people
        who know your world inside&nbsp;out.
        <br />
        Launch in as little as three months with hassle-free setup and&nbsp;affordable pricing.
        We're here to support you every step of the&nbsp;way.
      </>
    ),
    image: <img src={expertiseImage} alt="" />
  },
  emailForm: {
    title: (
      <>
        Questions? Curious?
        <br /> We're here to chat
      </>
    ),
    subtitle: (
      <>
        Are you ready to ship more quickly?
        <br /> Are you ready to take your business farther, faster?
      </>
    ),
    bookDemo: <>Book a demo</>,
    reachOut: <>I'll reach out to you.</>,
    person: {
      name: <>Piotr Kamoda</>,
      position: <>Co-founder</>,
      photo: <StaticImage src="./assets/people/piotr-kamoda.jpg" alt="" />
    },
    emailSent: (
      <>
        We will contact you within 48 hours.
        <br />
        Thanks!
      </>
    )
  },
  footer: {
    logo: <>Logo</>,
    quickLinks: <>Quick links</>,
    contactUs: <>Contact us</>,
    slogan: <>Development of your ideas</>,
    privacyPolicy: <>Privacy policy</>,
    copyright: <>© IMPACT Development {currentYear}</>
  }
}

export default { pl: en, en }
